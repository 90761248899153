<template>
  <b-form-group
    :label="$attrs.label"
    label-for="project-type"
  >
    <b-form-select
      id="project-type"
      v-model="typeProxy"
      :options="projectTypeOptions"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'ProjectTypeSelect',
  props: {
    type: { type: String, default: '' },
  },
  data() {
    return {
      projectTypeOptions: [
        { text: 'Select Project Type', value: null, disabled: true },
        { text: 'Crowdfunding', value: 'crowdfunding' },
        { text: 'Event', value: 'event' },
      ],
    }
  },
  computed: {
    typeProxy: {
      get() {
        return this.type
      },
      set(value) {
        this.$emit('update:type', value)
      },
    },
  },
}
</script>
<style lang="scss">

</style>

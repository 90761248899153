<template>
  <custom-vue-select
    id="presence-type"
    label="Presence Type"
    placeholder="Please select presence type"
    :rules="rules"
    :value-reducer="option=>option.value"
    :options="presenceTypeOptions"
    :selected.sync="tempPresenceType"
    :disabled="$attrs.disabled"
    text-field="text"
  />
</template>
<script>
import CustomVueSelect from '../common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'PresenceTypeSelect',
  components: { CustomVueSelect },
  props: {
    presenceType: { type: String, default: '' },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      presenceTypeOptions: [
        { text: 'Offline', value: 'in_personal' },
        { text: 'Online', value: 'online' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  computed: {
    tempPresenceType: {
      get() {
        return this.presenceType
      },
      set(value) {
        this.$emit('update:presenceType', value)
      },
    },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <b-form-group
    label="Donation Use"
    label-for="donation-use"
  >
    <b-form-select
      id="donation-use"
      v-model="donationUseProxy"
      :options="donationUseOptions"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'DonationUseSelect',
  props: {
    donationUse: { type: String, default: '' },
  },
  data() {
    return {
      donationUseOptions: [
        { text: 'Both', value: 'both' },
        { text: 'Donation', value: 'donation' },
        { text: 'Fundraising', value: 'fundraising' },
      ],
    }
  },
  computed: {
    donationUseProxy: {
      get() {
        return this.donationUse
      },
      set(value) {
        this.$emit('update:donationUse', value)
      },
    },
  },
}
</script>
<style lang="">

</style>

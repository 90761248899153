<template>
  <b-form-group
    label="Event"
    label-for="event"
  >
    <v-select
      id="event"
      v-model="eventProxy"
      label="event_name"
      :reduce="event => event.id"
      :options="eventsOptions"
    />
  </b-form-group>
</template>
<script>
import vSelect from 'vue-select'

export default {
  name: 'SelectEvent',
  components: {
    vSelect,
  },
  props: {
    forFundraising: { type: Boolean, default: null },
    event: { type: Number, default: null },
  },
  data() {
    return {
      eventsOptions: [],
    }
  },
  computed: {
    eventProxy: {
      get() {
        return this.event
      },
      set(value) {
        this.$emit('update:event', value)
      },
    },
  },
  created() {
    this.getEvents()
  },
  methods: {
    getEvents() {
      this.$activities.get('/internalops/upcoming-events/filter', {
        params: {
          organization: this.$store.getters['mainEntity/getEntityId'],
          forFundraising: this.forFundraising,
        },
      }).then(res => {
        this.eventsOptions = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <validation-provider
    #default="validationContext"
    name="Item Type"
    :rules="rules"
  >
    <b-form-group
      label-for="budget-item-type"
    >
      <template #label>

        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          Item Type
        </span>

      </template>
      <b-form-select
        id="budget-item-type"
        v-model="itemTypeProxy"
        :options="itemTypeOptions"
        :state="getValidationState(validationContext)"
        value-field="id"
        text-field="title"
      />
      <b-form-invalid-feedback>
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'BudgetItemTypeSelect',
  props: {
    itemType: { type: Number, default: null },
    rules: { type: String, default: '' },
    itemTypeOptions: { type: Array, default: () => [] },
  },
  computed: {
    itemTypeProxy: {
      get() {
        return this.itemType
      },
      set(value) {
        this.$emit('update:itemType', value)
      },
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
}
</script>
<style lang="scss">

</style>

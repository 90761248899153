<template>
  <fieldset :disabled="$attrs.disabled">
    <b-form-row>
      <!-- Topic -->
      <b-col
        v-if="!event.singleSession"
        md="4"
      >
        <text-input
          id="topic"
          rules="required|min:3|max:200"
          name="Topic"
          :text.sync="session.topic"
        />
      </b-col>
      <!-- Speaker -->
      <b-col md="4">
        <text-input
          id="speaker"
          rules="min:3|max:200"
          name="Speaker"
          :text.sync="session.speaker"
        />
      </b-col>
      <!-- Url -->
      <b-col md="4">
        <text-input
          id="url"
          :rules="`${event.presenceType !== 'in_personal' ? 'required' : ''}|url`"
          name="URL"
          type="url"
          :text.sync="session.url"
        />
      </b-col>
      <!-- Venue -->
      <b-col md="4">
        <text-input
          id="venue"
          rules="min:3|max:200"
          name="Venue"
          :text.sync="session.venue"
        />
      </b-col>
      <!-- Language -->
      <b-col md="4">
        <language-select
          :languages.sync="session.language"
          :is-multiple="false"
          :disabled="$attrs.disabled"
        />
      </b-col>
      <!-- Capacity -->
      <b-col md="4">
        <text-input
          id="capacity"
          name="Capacity"
          rules="required|min_value:1|max_value:16777215"
          type="number"
          :text.sync="session.capacity"
        />
      </b-col>
      <!-- Start Date -->
      <b-col
        v-if="!event.singleSession"
        md="4"
      >
        <date-time
          id="start-date-time"
          :datetime.sync="session.start_time"
          name="Start Datetime"
          rules="required"
          :config="{
            enableTime:true,
            dateFormat: 'Y-m-d H:i',
            minDate: event.startTime,
            maxDate:$moment(event.endTime).subtract({minutes:1}).format('YYYY-MM-DD HH:mm')
          }"
        />
      </b-col>
      <!-- End Date -->
      <b-col
        v-if="!event.singleSession"
        md="4"
      >
        <date-time
          id="end-date-time"
          :datetime.sync="session.end_time"
          name="End Datetime"
          rules="required"
          :config="{
            enableTime:true,
            dateFormat: 'Y-m-d H:i',
            minDate:$moment(session.start_time).add({minutes:1}).format('YYYY-MM-DD HH:mm'),
            maxDate:event.endTime
          }"
        />
      </b-col>
      <!-- Gender -->
      <b-col md="4">
        <gender-select
          :gender.sync="session.target_gender"
          :disabled="$attrs.disabled"
        />
      </b-col>
      <b-col
        v-if="session.target_gender === 'both'"
        md="8"
      >
        <!-- Max Male -->
        <b-form-row>
          <b-col md="6">
            <text-input
              id="max-male"
              name="Max Male"
              :rules="`max_value:${session.capacity}`"
              type="number"
              :disabled="!session.capacity"
              :text.sync="maxMaleProxy"
            />
          </b-col>
          <!-- Max Female -->
          <b-col md="6">
            <text-input
              id="max-female"
              name="Max Female"
              :rules="`max_value:${session.capacity}`"
              type="number"
              :disabled="!session.capacity"
              :text.sync="maxFemaleProxy"
            />
          </b-col>
        </b-form-row>
      </b-col>
      <!-- Restriction Notes -->
      <b-col cols="12">
        <restriction-notes :notes.sync="session.restriction_notes" />
      </b-col>
      <!-- General Notes -->
      <b-col
        v-if="!event.singleSession"
        cols="12"
        class="mb-2"
      >
        <rich-text-editor
          name="General Notes"
          rules="max:1500"
          :description.sync="session.general_notes"
        />
      </b-col>
      <!-- Age Segments -->
      <b-col cols="12">
        <age-segments
          :age-segments.sync="session.age_segments"
          :capacity="session.capacity"
          :segments-capacity-valid.sync="capacityValidProxy"
        />
      </b-col>
      <!-- Ticket Price -->
      <b-col
        cols="12"
        class="my-2"
      >
        <ticket-price :paid-ticket="session.paidTicket" />
      </b-col>
      <!-- Auto Approval -->
      <b-col
        md="4"
        class="mb-1 mb-md-0"
      >
        <b-form-checkbox
          id="session-auto-activate"
          v-model="session.auto_approval"
          :disabled="isAutoApprovalDisabled||session.paidTicket.paid==='paid'"
        >
          Auto Approval
        </b-form-checkbox>
      </b-col>
      <!-- Vaccination -->
      <b-col
        md="4"
        class="mb-1 mb-md-0"
      >
        <b-form-checkbox
          id="vaccination-required"
          v-model="session.vaccination_required"
          variant="primary"
        >
          Vaccination Required
        </b-form-checkbox>
      </b-col>
      <!-- Names Required -->
      <b-col
        md="4"
        class="mb-2"
      >
        <b-form-checkbox
          id="names-required"
          v-model="session.attendees_name_required"
        >
          Names Required
        </b-form-checkbox>
      </b-col>
      <!-- Status -->
      <b-col
        class="mb-1 mb-md-0"
      >
        <b-form-group
          label="Status"
          label-for="status"
        >
          <b-radio-group
            id="status"
            v-model="session.status"
            :options="[
              {text:'Active',value:true},
              {text:'Inactive',value:false}
            ]"
          />
        </b-form-group>
      </b-col>
    </b-form-row>
  </fieldset>
</template>
<script>
import DateTime from '@/common/components/common/FormInputs/DateTime.vue'
import GenderSelect from '@/common/components/common/FormInputs/GenderSelect.vue'
import LanguageSelect from '@/common/components/common/FormInputs/LanguageSelect.vue'
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import AgeSegments from './AgeSegments.vue'
import RestrictionNotes from './RestrictionNotes.vue'
import TicketPrice from './Tickets/TicketPrice.vue'
import RichTextEditor from '../../common/FormInputs/RichTextEditor.vue'

export default {
  name: 'SessionInputs',
  components: {
    LanguageSelect,
    DateTime,
    TextInput,
    GenderSelect,
    AgeSegments,
    TicketPrice,
    RestrictionNotes,
    RichTextEditor,
  },
  props: {
    session: { type: Object, default: () => {} },
    event: { type: Object, default: () => {} },
  },
  data() {
    return {
      isAutoApprovalDisabled: false,
    }
  },
  computed: {
    maxMaleProxy: {
      get() {
        return this.session.max_male_number
      },
      set(value) {
        this.$set(this.session, 'max_male_number', value)
        this.$set(this.session, 'max_female_number', this.session.capacity - value)
      },
    },
    maxFemaleProxy: {
      get() {
        return this.session.max_female_number
      },
      set(value) {
        this.$set(this.session, 'max_female_number', value)
        this.$set(this.session, 'max_male_number', this.session.capacity - value)
      },
    },
    capacityValidProxy: {
      get() {
        return this.segmentsCapacityValid
      },
      set(value) {
        return this.$emit('update:segmentsCapacityValid', value)
      },
    },
  },
  inject: ['segmentsCapacityValid'],
  watch: {
    'session.paidTicket.paid': function (val) {
      if (val === 'paid') {
        this.$set(this.session, 'auto_approval', true)
        this.isAutoApprovalDisabled = true
      } else if (val === 'mark-attending') {
        this.isAutoApprovalDisabled = true
        this.$set(this.session, 'auto_approval', false)
      } else {
        this.isAutoApprovalDisabled = false
      }
    },
  },
}
</script>
<style lang="">

</style>

<template>
  <div>
    <fieldset :disabled="$attrs.disabled">
      <validation-observer #default="{handleSubmit}">
        <event-inputs
          :event="event"
          :disabled="$attrs.disabled"
        />

        <session-inputs
          :session="session"
          :event="event"
          :disabled="$attrs.disabled===true"
        />

        <div
          v-if="!$attrs.disabled"
          class="text-right"
        >
          <submit-button v-bind="{handleSubmit,submit}" />
        </div>
      </validation-observer>
    </fieldset>
  </div>
</template>
<script>
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import EventInputs from './EventInputs.vue'
import SessionInputs from './Sessions/SessionInputs.vue'

export default {
  name: 'SingleSessionEventForm',
  components: { EventInputs, SessionInputs, SubmitButton },
  props: {
    event: { type: Object, default: () => ({}) },
    session: { type: Object, default: () => ({}) },
    submit: { type: Function, default: () => {} },
  },
  data() {
    return {
      segmentsCapacityValid: true,
    }
  },
  provide() {
    return {
      segmentsCapacityValid: this.segmentsCapacityValid,
    }
  },
}
</script>
<style lang="">

</style>

<template>
  <b-form-group
    label="Sessions Type"
    label-for="sessions-type"
    label-class="font-weight-bolder"
  >
    <b-radio-group
      id="sessions-type"
      v-model="sessionsTypeProxy"
      :options="[
        {text:'Single session',value:true},
        {text:'Multi session',value:false},
      ]"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'EventSessionsTypeSelect',
  props: {
    sessionsType: { type: Boolean, default: false },
  },
  computed: {
    sessionsTypeProxy: {
      get() {
        return this.sessionsType
      },
      set(value) {
        return this.$emit('update:sessionsType', value)
      },
    },
  },
}
</script>
<style lang="">

</style>

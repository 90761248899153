<template>
  <b-form-group
    label="Donation Type"
    label-for="donation-type"
  >
    <b-form-select
      id="donation-type"
      v-model="donationTypeProxy"
      :options="donationTypeOptions"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'DonationTypeSelect',
  props: {
    donationType: { type: String, default: '' },
  },
  data() {
    return {
      donationTypeOptions: [
        { text: 'Master', value: 'master' },
        { text: 'Custom', value: 'custom' },
      ],
    }
  },
  computed: {
    donationTypeProxy: {
      get() {
        return this.donationType
      },
      set(value) {
        this.$emit('update:donationType', value)
      },
    },
  },
}
</script>
<style lang="scss">

</style>

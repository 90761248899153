<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <!-- Cover Image -->
      <b-form-row>
        <b-col cols="12">
          <upload-files
            id="cover-image"
            label="Cover Image"
            accept="image/*"
            rules="image|image-aspect-ratio:2,1"
            :files.sync="media.coverImage"
          >
            <template #preview>
              <div>
                <images-preview
                  :images.sync="media.coverImage"
                />
              </div>
            </template>
          </upload-files>
        </b-col>
      </b-form-row>

      <!-- Video Url -->
      <text-input
        id="video-url"
        name="Video URL"
        rules="youtube-url"
        :text.sync="media.videoLink"
      />

      <!-- Thank You Message Type -->
      <div class="border-top mt-3 py-2">
        <b-form-group
          label="Custom Thank You Message"
          label-for="custom-message"
        >
          <b-form-radio-group
            id="custom-message"
            v-model="thankyouMediaTypeProxy"
            :options="messageTypeOptions"
            class="mt-1"
          />
        </b-form-group>
      </div>

      <b-form-row class="border-bottom pb-2 mb-2">
        <!-- Thank You Image -->
        <b-col
          v-if="media.thankyouMediaType === MEDIA_TYPE.IMAGE"
          md="6"
        >
          <div>
            <upload-files
              id="thanks-image"
              label="Thanks Image"
              accept="image/*"
              rules="image|image-aspect-ratio:2,1"
              :files.sync="media.thankyouMediaPath"
            >
              <template #preview>
                <div>
                  <images-preview
                    :images.sync="media.thankyouMediaPath"
                  />
                </div>
              </template>
            </upload-files>
          </div>
        </b-col>

        <!-- Thank You Video -->
        <b-col
          v-if="media.thankyouMediaType === MEDIA_TYPE.VIDEO"
          md="6"
        >
          <text-input
            id="thanks-video-url"
            name="Thanks Video URL"
            rules="youtube-url"
            :text.sync="media.thanks_video_url"
          />
        </b-col>
      </b-form-row>

      <!-- Log Notes -->
      <log-notes
        :notes.sync="media.logNotes"
      />

      <!-- Case Status Updates -->
      <case-updates :statuses.sync="media.caseStatuses" />

      <div
        v-if="!disabled"
        class="text-right mt-2"
      >
        <b-button
          variant="primary"
          class="mx-1"
          @click="handleSubmit(submit)"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import ImagesPreview from '../../../common/FormInputs/ImagesPreview.vue'
import TextInput from '../../../common/FormInputs/TextInput.vue'
import UploadFiles from '../../../common/FormInputs/UploadFiles.vue'
import CaseUpdates from '../ProjectMedia/CaseUpdates.vue'
import LogNotes from '../ProjectMedia/LogNotes.vue'

export default {
  name: 'ProjectMedia',
  components: {
    UploadFiles,
    ImagesPreview,
    TextInput,
    LogNotes,
    CaseUpdates,
  },
  props: {
    submit: { type: Function, default: () => {} },
    media: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      MEDIA_TYPE: {
        IMAGE: 1,
        VIDEO: 0,
      },
      messageType: '',
      messageTypeOptions: [
        { text: 'No Media', value: null },
        { text: 'Image', value: 1 },
        { text: 'Video', value: 0 },
      ],
    }
  },
  computed: {
    thankyouMediaTypeProxy: {
      get() {
        return this.media.thankyouMediaType
      },
      set(value) {
        this.$set(this.media, 'thankyouMediaType', value)
        if (value === null) { this.$set(this.media, 'thankyouMediaPath', null) }
      },
    },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <validation-provider
    #default="validationContext"
    name="Payment Method"
    :rules="rules"
  >
    <b-form-group
      label-for="payment-method"
    >
      <template #label>

        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          Payment Method
        </span>

      </template>

      <b-form-select
        id="payment-method"
        v-model="tempPaymentMethod"
        :options="paymentMethodOptions"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'

export default {

  name: 'PaymentMethodSelect',
  props: {
    paymentMethod: { type: String, default: '' },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      paymentMethodOptions: [
        { text: 'Online', value: 'online' },
        { text: 'At the door', value: 'at-door' },
        { text: 'Both', value: 'both' },
      ],
    }
  },
  computed: {
    tempPaymentMethod: {
      get() {
        return this.paymentMethod
      },
      set(value) {
        this.$emit('update:paymentMethod', value)
      },
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

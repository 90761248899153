<template>
  <b-form-row>
    <!-- Name -->
    <b-col md="4">
      <text-input
        id="name"
        rules="required|min:3|max:100"
        name="Event Name"
        :text.sync="event.name"
      />
    </b-col>

    <!-- Event Type Select -->
    <b-col md="4">
      <event-type-select
        :event-type.sync="event.types"
        rules="required"
        :disabled="disabled"
      />
    </b-col>

    <!-- Presence Type -->
    <b-col md="4">
      <presence-type-select
        :presence-type.sync="event.presenceType"
        rules="required"
        :disabled="disabled"
      />
    </b-col>

    <!-- Contact Name -->
    <b-col md="4">
      <text-input
        id="contact-name"
        rules="required|min:3|max:100"
        name="Contact Person Name"
        :text.sync="event.contactName"
      />
    </b-col>

    <!-- Contact Phone -->
    <b-col md="4">
      <text-input
        id="contact-phone"
        rules="required|min:6|max:100|phone"
        name="Contact Person Phone"
        :text.sync="event.contactNumber"
      />
    </b-col>

    <!-- Expected Attendees -->
    <b-col md="4">
      <text-input
        id="expected-attendees"
        name="Expected Attendees"
        type="number"
        :text.sync="event.expectedAttendeesNumber"
      />
    </b-col>

    <!-- Tickets Amount -->
    <b-col md="4">
      <text-input
        id="total-tickets-amount"
        name="Expected Tickets Amount"
        type="number"
        :text.sync="event.expectedTicketsAmount"
      />
    </b-col>

    <b-col
      v-if="$can('publishVisibility','EventController')"
      md="4"
    >
      <b-form-group
        label="Event visibility"
        label-for="event-visibility"
      >
        <b-form-radio-group
          id="event-visibility"
          v-model="event.visibility"
          :options="visibilityOptions"
          class="mt-1"
        />
      </b-form-group>
    </b-col>

    <!-- Frequency -->
    <b-col
      v-if="isAddForm"
      cols="12"
      class="my-2 border p-2"
    >
      <h5>Date</h5>
      <b-form-row>
        <b-col
          v-if="!event.singleSession"
          md="2"
        >
          <frequency-type-select :frequency.sync="event.frequency" />
        </b-col>
        <b-col md="10">
          <frequency-dates
            :frequency="event.frequency"
            :event-dates="event.eventDates"
          />
        </b-col>
      </b-form-row>
    </b-col>

    <!-- Edit Form Start Date -->
    <template v-else>
      <b-col md="6">
        <date-time
          id="start-date-time"
          :datetime.sync="event.startTime"
          name="Start Datetime"
          rules="required"
          :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
        />
      </b-col>

      <!-- Edit Form End Date -->
      <b-col md="6">
        <date-time
          id="end-date-time"
          :datetime.sync="event.endTime"
          name="End Datetime"
          rules="required"
          :config="{
            enableTime:true,
            dateFormat: 'Y-m-d H:i',
            minDate:$moment(event.startTime).add({minutes:1}).format('YYYY-MM-DD HH:mm') }"
        />
      </b-col>
    </template>

    <!-- General Notes -->
    <b-col
      cols="12"
      class="mb-2"
    >
      <rich-text-editor
        name="General Notes"
        rules="max:1500"
        :description.sync="event.generalNotes"
      />
    </b-col>

    <!-- Location -->
    <location-inputs
      v-if="event.presenceType !== 'online'"
      :details="event.location"
      :rules="['in_personal','both'].includes(event.presenceType) ? 'required': ''"
      class="mb-1"
    />

    <!-- Upload Image -->
    <b-col cols="12">
      <div>
        <upload-files
          id="event-image"
          label="Image"
          :disabled="disabled"
          rules="ext:jpg,jpeg,png|size:10240"
          accept="image/png, image/jpeg"
          :files.sync="event.image"
        >
          <template #preview>
            <div>
              <images-preview
                :disabled="disabled"
                :images.sync="event.image"
              />
            </div>
          </template>
        </upload-files>
      </div>

      <div
        class="mb-2"
      >
        <b-form-row class="text-warning mt-2">
          <b-col md="3">
            <li>Recommended image size: 2160x1080px</li>
          </b-col>
          <b-col md="3">
            <li>Image ratio: 2/1</li>
          </b-col>
          <b-col md="3">
            <li>Maximum file size: 10MB</li>
          </b-col>
          <b-col md="3">
            <li>Supported image files: JPEG or PNG</li>
          </b-col>
        </b-form-row>
      </div>
    </b-col>

    <!-- Upload Brochure -->
    <b-col cols="12">
      <b-form-row>
        <b-col
          cols="12"
        >
          <upload-files
            id="event-brochure"
            label="Brochure"
            :disabled="disabled"
            accept="application/pdf"
            rules="ext:pdf"
            :files.sync="event.brochure"
            multiple
          />

          <file-preview
            :file="event.brochure[0]"
          />
        </b-col>
      </b-form-row>
    </b-col>

    <!-- Auto Activate -->
    <b-col
      v-if="$can('publishAutoActivate','EventController')"
      cols="12"
    >
      <b-form-row
        class="mt-2 mb-1"
      >
        <b-col
          md="6"
        >
          <b-form-group
            label-for="auto-activate"
          >
            <template>
              <h5>
                Schedule auto activate
              </h5>
            </template>
            <b-form-radio-group
              id="auto-activate"
              v-model="isAutoActivateProxy"
              :options="[
                {text:'Publish Now',value:false},
                {text:'You can activate your event booking before its starting date',value:true},
              ]"
            />
          </b-form-group>
        </b-col>
        <b-col
          v-if="isAutoActivate"
          md="4"
        >
          <text-input
            id="auto-activate-value"
            name="Auto Activate Before (Hours)"
            placeholder="Hours"
            rules="required|min_value:1|max_value:999"
            type="number"
            :text.sync="event.activateBeforeHours"
          />
        </b-col>
      </b-form-row>
    </b-col>
  </b-form-row>
</template>
<script>
import PresenceTypeSelect from './PresenceTypeSelect.vue'
import TextInput from '../common/FormInputs/TextInput.vue'
import LocationInputs from '../common/FormInputs/LocationInputs.vue'
import EventTypeSelect from './EventTypeSelect.vue'
import FrequencyDates from './FrequencyDates.vue'
import FrequencyTypeSelect from '../common/FormInputs/FrequencyTypeSelect.vue'
import ImagesPreview from '../common/FormInputs/ImagesPreview.vue'
import UploadFiles from '../common/FormInputs/UploadFiles.vue'
import DateTime from '../common/FormInputs/DateTime.vue'
import FilePreview from '../common/FormInputs/FilePreview.vue'
import RichTextEditor from '../common/FormInputs/RichTextEditor.vue'

export default {
  name: 'EventInputs',
  components: {
    PresenceTypeSelect,
    TextInput,
    LocationInputs,
    EventTypeSelect,
    FrequencyDates,
    FrequencyTypeSelect,
    ImagesPreview,
    UploadFiles,
    DateTime,
    FilePreview,
    RichTextEditor,
  },
  props: {
    event: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isAutoActivate: false,
    }
  },
  computed: {
    publishNowValue() {
      return 65535
    },
    isAutoActivateProxy: {
      get() {
        return this.isAutoActivate
      },
      set(value) {
        this.isAutoActivate = value
        this.$set(this.event, 'activateBeforeHours', value ? 0 : this.publishNowValue)
      },
    },
    isAddForm() {
      return !this.$route.params.id
    },
    visibilityOptions() {
      return [
        { text: 'Events screen only', value: 'public' },
        { text: 'Organization screen only', value: 'private' },
        { text: 'Both of them', value: 'both' },
      ]
    },
  },
  created() {
    this.isAutoActivate = this.event.activateBeforeHours !== this.publishNowValue
  },
}
</script>
<style lang="scss" scoped>
</style>

<template>
  <div>
    <!-- Add Button -->
    <div>
      <b-button
        v-b-modal.add-note
        variant="primary"
        class="mb-1"
      >
        <span class="text-nowrap">Add Log Note</span>
      </b-button>
    </div>

    <!-- Table -->
    <b-card>
      <b-table
        ref="table"
        :items="notes"
        :fields="tableColumns"
        responsive
        empty-text="No notes found"
        show-empty
      >
        <template #cell(message)="{item}">
          <!-- eslint-disable-next-line vue/no-v-html -->
          <span v-html="item.message" />
        </template>
        <template #cell(actions)="{item,index}">
          <b-dropdown
            variant="link"
            no-caret
          >
            <template #button-content>
              <feather-icon
                icon="MoreVerticalIcon"
                size="16"
                class="align-middle text-body"
              />
            </template>
            <b-dropdown-item
              v-b-modal.edit-note
              @click="setSelectedNote(item,index)"
            >
              <feather-icon icon="EditIcon" />
              <span class="align-middle ml-50"> Edit </span>
            </b-dropdown-item>
            <b-dropdown-item @click="deleteNote(index)">
              <feather-icon icon="TrashIcon" />
              <span class="align-middle ml-50"> Delete </span>
            </b-dropdown-item>
          </b-dropdown>
        </template>
      </b-table>
    </b-card>

    <!-- Add Note -->
    <b-modal
      id="add-note"
      centered
      title="Add Note"
      hide-footer
      @hidden="resetSelectedNote"
    >
      <log-notes-form
        :note="selectedNote"
        :submit="addNote"
      />
    </b-modal>

    <!-- Edit Note -->
    <b-modal
      id="edit-note"
      centered
      title="Edit Note"
      hide-footer
      @hidden="resetSelectedNote"
    >
      <log-notes-form
        :note="selectedNote"
        :submit="editNote"
      />
    </b-modal>
  </div>
</template>
<script>
import LogNotesForm from './LogNotesForm.vue'

export default {
  name: 'LogNotes',
  components: { LogNotesForm },
  props: {
    notes: { type: Array, default: () => [] },
  },
  data() {
    return {
      selectedNote: { message: '', user: this.$store.getters['auth/getUserData'].username, created_at: this.$moment().format('YYYY-MM-DD HH:mm') },
      tableColumns: [
        { key: 'message' },
        { key: 'user' },
        { key: 'created_at' },
        { key: 'actions' },
      ],
    }
  },
  methods: {
    setSelectedNote(item, index) {
      this.selectedNote = { index, ...item }
    },
    deleteNote(index) {
      this.$delete(this.notes, index)
    },
    addNote() {
      this.notes.push(this.selectedNote)

      this.$bvModal.hide('add-note')
      this.resetSelectedNote()
    },
    editNote() {
      this.$set(this.notes, this.selectedNote.index, this.selectedNote)

      this.$bvModal.hide('edit-note')
      this.resetSelectedNote()
    },
    resetSelectedNote() {
      this.selectedNote = { message: '', user: this.$store.getters['auth/getUserData'].username, created_at: this.$moment().format('YYYY-MM-DD HH:mm') }
    },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <custom-vue-select
    id="fundraising-project"
    label="Fundraising Project"
    text-field="title"
    :rules="rules"
    :options="fundraisingProjects"
    :selected.sync="fundraisingProxy"
    :data-provider="setFundraisingOptions"
  />
</template>
<script>
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'
import CustomVueSelect from '@/common/components/common/FormInputs/CustomVueSelect.vue'

export default {
  name: 'FundraisingProjectSelect',
  components: {
    CustomVueSelect,
  },
  props: {
    fundraisingId: { type: Number, default: null },
    filter: { type: Object, default: () => {} },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      fundraisingProjects: [],
    }
  },
  computed: {
    fundraisingProxy: {
      get() {
        return this.fundraisingId
      },
      set(value) {
        this.$emit('update:fundraisingId', value)
      },
    },
  },
  setup() {
    const { getFundraisingProjects } = FundraisingApi()
    return { getFundraisingProjects }
  },
  created() {
    this.setFundraisingOptions()
  },
  methods: {
    setFundraisingOptions(title) {
      this.getFundraisingProjects({ title, ...this.filter, entityId: this.$store.getters['mainEntity/getEntityId'] }).then(res => {
        this.fundraisingProjects = res.data.data
      })
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>

      <b-form-row>
        <!-- Total Budget -->
        <b-col md="6">
          <text-input
            id="total-budget"
            name="Total Proposed Budget"
            type="number"
            rules="required"
            :text.sync="plan.budget"
          />
        </b-col>

        <!-- Is There Detailed Estimated List -->
        <b-col
          md="4"
          class="ml-2 mt-2"
        >
          <b-form-checkbox
            id="is-budget-details"
            v-model="isBudgetDetails"
            switch
            inline
          >
            Detailed Estimated List
          </b-form-checkbox>
        </b-col>
      </b-form-row>

      <!-- Budget Details -->
      <div
        v-show="isBudgetDetails"
        class="my-2 border p-2"
      >
        <!-- Budget Items -->
        <budget-items
          :items="plan.budgetItems"
        />

        <b-form-row class="mt-2">
          <!-- Calculated Budget -->
          <b-col md="6">
            <text-input
              id="calculated-budget"
              name="Total Calculated Budget Items"
              placeholder="CAD"
              :disabled="true"
              :text.sync="calculatedBudgetProxy"
            />
          </b-col>

          <!-- Remaining Budget -->
          <b-col md="6">
            <text-input
              id="remaining-budget"
              name="Remaining Budget"
              placeholder="CAD"
              :disabled="true"
              :text.sync="remainingBudgetProxy"
            />
          </b-col>
        </b-form-row>
      </div>

      <!-- CheckList -->
      <check-list :items="plan.tasks" />

      <div
        v-if="!disabled"
        class="text-right mt-3"
      >
        <b-button
          variant="primary"
          class="mx-1"
          @click="handleSubmit(submit)"
        >
          Save
        </b-button>
      </div>
    </b-form>
  </validation-observer>
</template>
<script>
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import BudgetItems from '@/common/components/Fundraising/CrowdFundraising/ProjectPlan/BudgetItems.vue'
import CheckList from '../ProjectPlan/CheckList.vue'

export default {
  name: 'ProjectPlan',
  components: {
    TextInput,
    BudgetItems,
    CheckList,
  },
  props: {
    plan: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      isBudgetDetails: true,
    }
  },
  computed: {
    calculatedBudgetProxy() {
      return this.plan.budgetItems.reduce((acc, currentValue) => acc + parseInt(currentValue.price, 10), 0)
    },
    remainingBudgetProxy() {
      return (this.plan.budget - this.calculatedBudgetProxy) || 0
    },

  },
}
</script>
<style lang="scss">

</style>

<template>
  <div>
    <b-form-row>
      <!-- Start Once -->
      <b-col
        v-if="frequency === 'once'"
      >
        <b-form-row>
          <b-col md="6">
            <date-time
              id="start-date-time"
              :datetime.sync="tempEventDates.startDate"
              name="Start Datetime"
              rules="required"
              :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
            />
          </b-col>

          <b-col md="6">
            <date-time
              id="end-date-time"
              :datetime.sync="tempEventDates.endDate"
              name="End Datetime"
              rules="required"
              :config="{
                enableTime:true,
                dateFormat: 'Y-m-d H:i',
                minDate:$moment(tempEventDates.startDate).add({minutes:1}).format('YYYY-MM-DD HH:mm') }"
            />
          </b-col>
        </b-form-row>
      </b-col>
      <!-- End Once -->

      <!-- Start Multiple Dates -->
      <b-col
        v-else-if="frequency === 'custom'"
        sm="4"
      >
        <date-time
          id="multiple-dates"
          :datetime.sync="tempEventDates.days"
          name="Days"
          rules="required"
          :config="{ mode: 'multiple',dateFormat: 'Y-m-d', minDate: 'today'}"
        />
      </b-col>
      <!-- End Multiple Dates -->

      <!-- Start Date Range -->
      <b-col
        v-else-if="['daily','weekly'].includes(frequency)"
        sm="3"
      >
        <date-range-days :range-days.sync="tempEventDates.days" />
      </b-col>
      <!-- End Date Range -->

      <!-- Start Week Days -->
      <b-col
        v-if="frequency === 'weekly'"
        sm="3"
      >
        <week-days-select
          rules="required"
          @day-selected="setDatesMatchedWithDaysNames"
        />
      </b-col>
      <!-- End Week Days -->

      <template v-if="frequency !== 'once'">
        <!-- Start Time -->
        <b-col
          sm="3"
        >
          <date-time
            id="start-time"
            :datetime.sync="tempEventDates.startTime"
            name="Start Time"
            rules="required"
            :config="{
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
              altInput: true,
              altFormat: 'h:i K',
            }"
          />
        </b-col>
        <!-- Start Time -->

        <!-- End Time -->
        <b-col
          sm="3"
        >
          <date-time
            id="end-time"
            :datetime.sync="tempEventDates.endTime"
            name="End Time"
            rules="required"
            :config="{
              enableTime: true,
              noCalendar: true,
              dateFormat: 'H:i',
              minTime:minEndTime,
              altInput: true,
              altFormat: 'h:i K',
            }"
          />
        </b-col>
      <!-- End Time -->
      </template>
    </b-form-row>
  </div>
</template>
<script>
import DateRangeDays from '../common/FormInputs/DateRangeDays.vue'
import DateTime from '../common/FormInputs/DateTime.vue'
import WeekDaysSelect from '../common/FormInputs/WeekDaysSelect.vue'

export default {
  name: 'FrequencyDates',
  components: { DateTime, DateRangeDays, WeekDaysSelect },
  props: {
    frequency: { type: String, default: '' },
    eventDates: { type: Object, default: () => {} },
  },
  computed: {
    tempEventDates: {
      get() {
        return this.eventDates
      },
      set(value) {
        this.$emit('update:eventDates', value)
      },
    },
    minEndTime() {
      const startTimeParts = this.tempEventDates.startTime?.split(':') ?? []
      const startTime = { hour: startTimeParts[0], minute: startTimeParts[1] }
      return this.$moment().set({
        hour: startTime.hour,
        minute: startTime.minute,
      }).add({ minutes: 1 }).format('HH:mm')
    },
  },
  watch: {
    'tempEventDates.startTime': function () {
      this.$set(this.tempEventDates, 'endTime', '')
    },
  },
  methods: {
    setDatesMatchedWithDaysNames(weekDays) {
      const dates = []
      const datesWithDaysNames = this.tempEventDates.days.map(day => ({ date: day, dayName: this.$moment(day).format('dddd') }))

      weekDays.forEach(day => {
        const dayDates = datesWithDaysNames.filter(date => date.dayName === day)
        const matchedDates = dayDates.map(date => date.date)
        dates.push(...matchedDates)
      })

      const uniqueDates = [...new Set(dates)]
      this.tempEventDates.weekDates = uniqueDates
    },
  },
}
</script>
<style lang="">

</style>

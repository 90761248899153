import axiosInstances from '@/libs/axios-instances'
import handleAlerts from '../common/handleAlerts'

export default function ProjectStatus() {
  const { successfulOperationAlert } = handleAlerts()

  const startProject = id => {
    axiosInstances.activities.post(`/internalops/fundraising/project/${id}/status/start`).then(() => {
      successfulOperationAlert('Project is started successfully')
    })
  }

  const stopProject = id => {
    axiosInstances.activities.post(`/internalops/fundraising/project/${id}/status/stop`).then(() => {
      successfulOperationAlert('Project is stopped successfully')
    })
  }

  return { startProject, stopProject }
}

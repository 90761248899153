<template>
  <validation-provider
    #default="validationContext"
    name="Weekly"
    :rules="rules"
    :detect-input="false"
  >
    <b-form-group label-for="week-days">
      <template #label>
        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          Week Days
        </span>
      </template>

      <v-select
        id="week-days"
        v-model="weekDays"
        :options="weekDaysOptions"
        :reduce="day=>day.name"
        label="name"
        multiple
        :close-on-select="false"
        @input="(e)=>{onInput(e);validationContext.validate(weekDays)}"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import vSelect from 'vue-select'
import formValidation from '@/@core/comp-functions/forms/form-validation'

export default {
  name: 'WeekDaysSelect',
  components: {
    vSelect,
  },
  props: {
    rules: { type: [String, Object], default: '' },
  },
  data() {
    return {
      weekDays: [],
      weekDaysOptions: [
        { id: 0, name: 'Saturday' },
        { id: 1, name: 'Sunday' },
        { id: 2, name: 'Monday' },
        { id: 3, name: 'Tuesday' },
        { id: 4, name: 'Wednesday' },
        { id: 5, name: 'Thursday' },
        { id: 6, name: 'Friday' },
      ],
    }
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
  methods: {
    onInput(value) {
      this.$emit('day-selected', value)
    },
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

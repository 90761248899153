/* eslint-disable no-use-before-define */
import { computed, ref } from '@vue/composition-api'
import store from '@/store'
import axiosInstances from '@/libs/axios-instances'
import router from '@/router'
import handleAlerts from '../common/handleAlerts'
import handleFormData from '../common/handleFormData'

export default function FundraisingApi() {
  const { setFormData, formData } = handleFormData()
  const { successfulOperationAlert } = handleAlerts()

  const SUCCESS_MESSAGES = Object.freeze({
    0: 'Crowdfund is drafted successfully',
    1: 'Crowdfund is sent for approval successfully',
    2: 'Crowdfund is published successfully',
  })
  const STATUSES = Object.freeze({
    DRAFTED: 0,
    SENT_FOR_APPROVAL: 1,
    APPROVE_STATUS: 2,
  })

  const loadingFundraising = ref(true)
  const mainInfoSubmitted = ref(false)

  const crowdfund = ref({
    id: null,
    mainInfo: {
      entityId: store.getters['mainEntity/getEntityId'],
      status: 0,
      active: false,
      zakatVerified: false,
      caseOnApp: false,
      visibility: 'both',
      type: 'crowdfunding',
      phone: store.getters['auth/getUserData'].profile?.phone,
      email: store.getters['auth/getUserData'].email,
      name: store.getters['auth/getUserData'].username,
    },
    location: {},
    plan: { budget: 0, budgetItems: [], tasks: [] },
    media: {
      logNotes: [], caseStatuses: [], coverImage: [],
    },
  })

  const crowdfundMediaFormatted = computed(() => (
    {
      videoLink: crowdfund.value.media.videoLink,
      coverImage: crowdfund.value.media.coverImage[0],
      logNotes: crowdfund.value.media.logNotes.map(note => note.message),
      ...(
        crowdfund.value.media.thankyouMediaPath
          ? {
            thankyouMediaPath: crowdfund.value.media.thankyouMediaPath[0],
            thankyouMediaType: crowdfund.value.media.thankyouMediaType,
          }
          : {}
      ),
    }
  ))

  const setCrowdfundData = crowdfundResponse => {
    crowdfund.value = {
      id: crowdfundResponse.id,
      mainInfo: {
        entityId: crowdfundResponse.entityId,
        title: crowdfundResponse.title,
        caseOnApp: crowdfundResponse.caseOnApp,
        zakatVerified: crowdfundResponse.zakatVerified,
        categories: crowdfundResponse.categoriesIDs,
        name: crowdfundResponse.name,
        phone: crowdfundResponse.phone,
        website: crowdfundResponse.website,
        type: crowdfundResponse.type,
        description: crowdfundResponse.description,
        toBeRaised: crowdfundResponse.toBeRaised,
        startTime: crowdfundResponse.startTime,
        endTime: crowdfundResponse.endTime,
        active: crowdfundResponse.active,
        email: crowdfundResponse.email,
        typeId: crowdfundResponse.typeId,
        visibility: crowdfundResponse.visibility,
      },
      plan: {
        budget: crowdfundResponse.budget,
        budgetItems: crowdfundResponse.budgetItems,
        tasks: crowdfundResponse.tasks,
      },
      media: {
        coverImage: [crowdfundResponse.coverImage],
        thankyouMediaPath: [crowdfundResponse.thankyouMediaPath],
        thankyouMediaType: crowdfundResponse.thankyouMediaType,
        videoLink: crowdfundResponse.videoLink,
        thanks_video_url: crowdfundResponse.thanks_video_url,
        logNotes: crowdfundResponse.logNotes,
        caseStatuses: crowdfundResponse.caseStatuses.map(status => ({ ...status, photo: [status.photo] })),
      },
      location: crowdfundResponse.location ?? {},
    }
  }

  const addCrowdFundMainInfo = (status = STATUSES.APPROVE_STATUS) => axiosInstances.activities.post('/internalops/fundraising/',
    {
      ...crowdfund.value.mainInfo,
      status,
    }).then(res => {
    crowdfund.value.id = res.data.data.id

    mainInfoSubmitted.value = true
    successfulOperationAlert(SUCCESS_MESSAGES[status])
  })

  const editCrowdFundMainInfo = (status = STATUSES.APPROVE_STATUS) => {
    const id = crowdfund.value.id ?? router.currentRoute.params.id

    return axiosInstances.activities.put(`/internalops/fundraising/${id}`,
      {
        ...crowdfund.value.mainInfo,
        status,
      }).then(res => {
      crowdfund.value.id = res.data.data.id
      successfulOperationAlert(SUCCESS_MESSAGES[status])
    })
  }

  const addCrowdFundLocation = () => axiosInstances.activities.post(`/internalops/fundraising/project/${crowdfund.value.id}/address`, crowdfund.value.location).then(() => {
    successfulOperationAlert('Crowdfund location is submitted successfully')
  })
  const addCrowdFundPlan = () => axiosInstances.activities.post(`/internalops/fundraising/project/${crowdfund.value.id}/budget`,
    {
      ...crowdfund.value.plan,
      tasks: crowdfund.value.plan.tasks.map(task => task.name),
    }).then(() => {
    successfulOperationAlert('Crowdfund plan is submitted successfully')
  })

  const addCrowdFundMedia = () => {
    setFormData(crowdfundMediaFormatted.value)
    appendCaseStatusesToFormData()

    return axiosInstances.activities.post(`/internalops/fundraising/project/${crowdfund.value.id}/media`, formData.value).then(() => {
      successfulOperationAlert('Crowdfund media is submitted successfully')
    })
  }

  const appendCaseStatusesToFormData = () => {
    const caseStatusesFormatted = crowdfund.value.media.caseStatuses.map(status => ({ ...status, photo: status.photo[0] }))

    caseStatusesFormatted.forEach((ele, index) => {
      Object.entries(ele).forEach(([key, value]) => {
        formData.value.append(`caseStatuses[${index}][${key}]`, value)
      })
    })
  }

  const getFundraisingProject = (id = router.currentRoute.params.id) => {
    axiosInstances.activities.get(`/internalops/fundraising/${id}`).then(res => {
      loadingFundraising.value = false

      const crowdfundResponse = res.data.data
      setCrowdfundData(crowdfundResponse)
    }).catch(() => { loadingFundraising.value = false })
  }

  const getFundraisingProjects = params => axiosInstances.activities.get('/internalops/fundraising', {
    params,
  }).then(res => res).catch(() => [])

  return {
    addCrowdFundMainInfo, editCrowdFundMainInfo, addCrowdFundLocation, addCrowdFundMedia, addCrowdFundPlan, crowdfund, getFundraisingProject, getFundraisingProjects, loadingFundraising, mainInfoSubmitted,
  }
}

<template>
  <validation-provider
    #default="validationContext"
    name="Fees Payer"
    :rules="rules"
  >
    <b-form-group
      label-for="fees-payer"
    >
      <template #label>

        <span
          :class="{required:JSON.stringify(rules).includes('required')}"
        >
          Transaction Fees Payer
        </span>

      </template>

      <b-form-select
        id="fees-payer"
        v-model="tempFeesPayer"
        :options="feesPayerOptions"
      />
      <b-form-invalid-feedback :state="getValidationState(validationContext)">
        {{ validationContext.errors[0] }}
      </b-form-invalid-feedback>
    </b-form-group>
  </validation-provider>
</template>
<script>
import formValidation from '@core/comp-functions/forms/form-validation'

export default {
  name: 'FeesPayerSelect',
  props: {
    feesPayer: { type: Boolean, default: true },
    rules: { type: String, default: '' },
  },
  data() {
    return {
      feesPayerOptions: [
        { text: 'User', value: true },
        { text: 'Organization', value: false },
      ],
    }
  },
  computed: {
    tempFeesPayer: {
      get() {
        return this.feesPayer
      },
      set(value) {
        this.$emit('update:feesPayer', value)
      },
    },
  },
  setup() {
    const { getValidationState } = formValidation()
    return { getValidationState }
  },
}
</script>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>

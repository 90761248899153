<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <!-- Project Title -->
      <div class="mb-2">
        <text-input
          id="project-title"
          name="Project Title"
          rules="required|min:3|max:100"
          :text.sync="info.title"
        />
      </div>

      <b-form-row class="mb-1">
        <!-- On MuslimDo App -->
        <b-col
          md="4"
        >
          <b-form-group
            label="Project On MuslimDo App"
            label-for="on-muslimdo"
          >
            <b-form-radio-group
              id="on-muslimdo"
              v-model="info.active"
              :options="booleanOptions"
              :disabled="!$can('publish','FundraisingController')"
              class="mt-1"
            />
          </b-form-group>
        </b-col>

        <b-col
          md="4"
        >
          <b-form-group
            label="Project visibility"
            label-for="project-visibility"
          >
            <b-form-radio-group
              id="project-visibility"
              v-model="info.visibility"
              :options="visibilityOptions"
              class="mt-1"
            />
          </b-form-group>
        </b-col>

        <!-- Zakat Verified -->
        <b-col md="4">
          <b-form-group
            label="Zakat Verified"
            label-for="zakat-verified"
          >
            <b-form-radio-group
              id="zakat-verified"
              v-model="info.zakatVerified"
              :options="booleanOptions"
              class="mt-1"
            />
          </b-form-group>
        </b-col>
      </b-form-row>

      <b-form-row>
        <!-- Project Fundraising Category -->
        <b-col md="4">
          <donation-category-select
            ref="donation_category_select"
            name="Project Fundraising Category"
            rules="required"
            multiple
            type="master"
            use-type="fundraising"
            status="active"
            :entity-id="$store.getters['mainEntity/getEntityId']"
            :disabled="disabled"
            :donation-category.sync="info.categories"
          />
        </b-col>

        <!-- Add Category -->
        <b-col
          md="3"
          class="ml-2 mt-2"
        >
          <b-link
            v-b-modal.add-modal
            variant="primary"
          >
            <span class="text-nowrap">Create new category</span>
          </b-link>

          <b-modal
            id="add-modal"
            centered
            title="Add Category"
            hide-footer
          >
            <add-donation-category
              :is-fundraising-form="true"
              @refresh="refreshDonationCategoriesList"
            />
          </b-modal>
        </b-col>
      </b-form-row>

      <b-form-row>
        <!-- Fundraiser Name -->
        <b-col md="4">
          <text-input
            id="fundraiser-name"
            name="Fundraiser Name"
            rules="min:3|max:100"
            :text.sync="info.name"
          />
        </b-col>

        <!-- Fundraiser Email -->
        <b-col md="4">
          <text-input
            id="fundraiser-email"
            name="Fundraiser Email"
            type="email"
            rules="email"
            :text.sync="info.email"
          />
        </b-col>

        <!-- Fundraiser Phone -->
        <b-col md="4">
          <text-input
            id="fundraiser-phone"
            rules="max:20|phone|min:3|max:100"
            name="Fundraiser Phone"
            :text.sync="info.phone"
          />
        </b-col>

        <!-- Fundraiser Website -->
        <b-col md="4">
          <text-input
            id="fundraiser-website"
            name="Fundraiser Website"
            rules="url|min:3|max:100"
            :text.sync="info.website"
          />
        </b-col>

        <!-- Project Type -->
        <template v-if="isFundraisingForm">
          <b-col md="4">
            <project-type-select
              label="Project Type"
              :type.sync="info.type"
            />
          </b-col>

          <!-- Select Event -->
          <b-col
            v-if="info.type === 'event'"
            md="4"
          >
            <select-event
              :event.sync="info.typeId"
              :for-fundraising="true"
            />
          </b-col>
        </template>
      </b-form-row>

      <!-- Project Description -->
      <rich-text-editor
        name="Project Description"
        :description.sync="info.description"
        :disabled="disabled"
      />

      <!-- Target to be raised -->
      <text-input
        id="target-to-be-raised"
        name="Target To Be Raised"
        type="number"
        placeholder="CAD"
        rules="required"
        :text.sync="info.toBeRaised"
      />

      <b-form-row>
        <!-- Start Datetime -->
        <b-col md="6">
          <date-time
            id="start-date-time"
            :datetime.sync="info.startTime"
            name="Start Datetime"
            rules="required"
            :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: 'today'}"
          />
        </b-col>

        <!-- End Datetime -->
        <b-col md="6">
          <date-time
            id="end-date-time"
            :datetime.sync="info.endTime"
            name="End Datetime"
            rules="required"
            :config="{ enableTime:true,dateFormat: 'Y-m-d H:i', minDate: $moment(info.startTime).add({minutes:1}).toDate()}"
          />
        </b-col>
      </b-form-row>

      <b-row
        v-if="fundraisingIsNotOver || !isEditForm"
        class="mt-3 justify-content-end"
      >
        <b-col
          v-if="isEditForm && $moment().isAfter(info.startTime)"
          md="2"
          class="mb-1 mb-md-1"
        >
          <b-button
            variant="danger"
            block
            @click="stopProject($route.params.id)"
          >
            Stop
          </b-button>
        </b-col>

        <b-col
          md="2"
          class="mb-1 mb-md-1"
        >
          <b-button
            v-if="isEditForm && $moment().isBefore(info.startTime)"
            variant="outline-primary"
            block
            @click="startProject($route.params.id)"
          >
            Start
          </b-button>
        </b-col>

        <b-col
          md="2"
          class="mb-1 mb-md-1"
        >
          <b-button
            variant="outline-primary"
            block
            @click="handleSubmit(()=>{submit(status.DRAFTED)})"
          >
            Save as draft
          </b-button>
        </b-col>

        <b-col
          md="2"
          class="mb-1 mb-md-1"
        >
          <b-button
            variant="outline-primary"
            block
            @click="handleSubmit(()=>{submit(status.SEND_FOR_APPROVAL)})"
          >
            Send for approval
          </b-button>
        </b-col>

        <b-col
          md="2"
          class="mb-1 mb-md-1"
        >
          <b-button
            v-if="$can('approve','FundraisingController')"
            variant="primary"
            block
            @click="handleSubmit(()=>{submit(status.APPROVED)})"
          >
            Approve & Publish
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import ProjectStatus from '@/common/compositions/Fundraising/ProjectStatus'
import SelectEvent from '@/common/components/QuickEvents/Bookings/SelectEvent.vue'
import DonationCategorySelect from '../../../common/FormInputs/DonationCategorySelect.vue'
import DateTime from '../../../common/FormInputs/DateTime.vue'
import RichTextEditor from '../../../common/FormInputs/RichTextEditor.vue'
import AddDonationCategory from '../../../GeneralSettings/donation-categories/AddDonationCategory.vue'
import ProjectTypeSelect from '../MainInfo/ProjectTypeSelect.vue'

export default {
  name: 'MainInfo',
  components: {
    TextInput,
    DonationCategorySelect,
    DateTime,
    RichTextEditor,
    AddDonationCategory,
    ProjectTypeSelect,
    SelectEvent,
  },
  props: {
    info: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
  data() {
    return {
      status: {
        DRAFTED: 0,
        SEND_FOR_APPROVAL: 1,
        APPROVED: 2,
      },
      booleanOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    }
  },
  computed: {
    isEditForm() {
      return !!this.$route.params.id
    },
    isFundraisingForm() {
      return this.$route.path.split('/')[1] === 'fundraising'
    },
    projectType() {
      return this.isFundraisingForm ? 'Fundraising' : 'Fundraising Events'
    },
    visibilityOptions() {
      return [
        { text: `Both in ${this.projectType} and organization screen`, value: 'both' },
        { text: `${this.projectType} list only`, value: 'public' },
        { text: 'Organization screen only (Private project)', value: 'private' },
      ]
    },
    fundraisingIsNotOver() {
      return this.$moment().isBefore(this.info.endTime)
    },
  },
  setup() {
    const { startProject, stopProject } = ProjectStatus()
    return { startProject, stopProject }
  },
  methods: {
    refreshDonationCategoriesList() {
      this.$refs.donation_category_select.getDonationCategories()
    },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <date-time
    id="date-range"
    :datetime.sync="range"
    name="Date Range"
    rules="required"
    :config="{ mode: 'range', dateFormat: 'Y-m-d', minDate: 'today'}"
  />
</template>
<script>
import DateTime from './DateTime.vue'

export default {
  name: 'DateRangeDays',
  components: { DateTime },
  data() {
    return {
      range: '',
    }
  },
  watch: {
    range() {
      const [startDate, endDate] = this.range.split(' to ')
      if (startDate && endDate) {
        const days = this.enumerateDaysBetweenDates(startDate, endDate)
        this.$emit('update:rangeDays', days)
      }
    },
  },
  methods: {
    enumerateDaysBetweenDates(startDate, endDate) {
      const dates = []

      const currDate = this.$moment(startDate).subtract(1, 'day')
      const lastDate = this.$moment(endDate)

      while (currDate.add(1, 'days').diff(lastDate, 'days') < 1) {
        dates.push(currDate.format('YYYY-MM-DD'))
      }

      return dates
    },
  },
}
</script>
<style lang="">

</style>

<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <fieldset :disabled="disabled">
        <event-inputs
          :event="event"
          :disabled="disabled"
        />

        <b-form-row>
          <!-- Submit -->
          <submit-button
            v-if="!disabled"
            v-bind="{submit,handleSubmit}"
            class="ml-auto"
          />
        </b-form-row>
      </fieldset>
    </b-form>
  </validation-observer>
</template>
<script>
import SubmitButton from '../common/FormInputs/SubmitButton.vue'
import EventInputs from './EventInputs.vue'

export default {
  name: 'EventForm',
  components: {
    SubmitButton,
    EventInputs,
  },
  props: {
    submit: { type: Function, default: () => null },
    event: { type: Object, default: () => {} },
    disabled: { type: Boolean, default: false },
  },
}
</script>
<style lang="scss">

</style>

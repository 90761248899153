<template>
  <b-card>
    <b-row>
      <b-col>
        <event-sessions-type-select :sessions-type.sync="sessionsType" />
      </b-col>
      <b-col
        class="mt-5"
        md="2"
      >
        <b-button
          block
          variant="primary"
          @click="saveSessionsType"
        >
          Save
        </b-button>
      </b-col>
    </b-row>
  </b-card>
</template>
<script>
import EventSessionsTypeSelect from './EventSessionsTypeSelect.vue'

export default {
  name: 'EventSessionsTypeWizard',
  components: { EventSessionsTypeSelect },
  props: {
    sessionsType: { type: Boolean, default: true },
  },
  methods: {
    saveSessionsType() {
      this.$emit('update:sessionsType', this.sessionsType)
      this.$emit('update:isSessionsTypeWizardSaved', true)
    },
  },
}
</script>
<style lang="">

</style>

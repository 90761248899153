<template>
  <b-form-row>
    <b-col md="6">
      <b-form-group
        label="Ticket Type"
        label-for="ticket-type"
      >
        <b-form-select
          id="ticket-type"
          v-model="tempDeductionType"
          :options="typesOptions"
        />
      </b-form-group>
    </b-col>

    <b-col md="6">
      <text-input
        id="amount"
        name="Amount"
        :rules="`${rules[tempDeductionType]}`"
        type="number"
        :text.sync="tempDeductionAmount"
      />
    </b-col>
  </b-form-row>
</template>
<script>
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'

export default {
  name: 'CharityTicket',
  components: { TextInput },
  props: {
    ticketPrice: { type: [Number, String], default: null },
    deductionAmount: { type: [Number, String], default: null },
    deductionType: { type: String, default: '' },
  },
  data() {
    return {
      amountType: '',
      typesOptions: [
        { text: 'Ratio', value: 'ratio' },
        { text: 'Value', value: 'value' },
      ],
    }
  },
  computed: {
    tempDeductionAmount: {
      get() {
        return this.deductionAmount
      },
      set(value) {
        this.$emit('update:deductionAmount', value)
      },
    },
    tempDeductionType: {
      get() {
        return this.deductionType
      },
      set(value) {
        this.$emit('update:deductionType', value)
      },
    },
    rules() {
      return {
        ratio: 'required|max_value:100',
        value: `required|max_value:${this.ticketPrice}`,
      }
    },
  },
}
</script>
<style lang="">

</style>

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h5',{staticClass:"mb-2"},[_vm._v(" Estimated Budget Items ")]),_c('div',[_c('b-form',{ref:"form",staticClass:"repeater-form",style:({height: _vm.trHeight}),on:{"submit":function($event){$event.preventDefault();return _vm.repeatAgain($event)}}},_vm._l((_vm.itemsProxy),function(item,index){return _c('b-form-row',{key:item.id,ref:"row",refInFor:true},[_c('b-col',{attrs:{"md":"3"}},[_c('budget-item-type-select',{attrs:{"rules":"required","item-type":item.id,"item-type-options":_vm.itemTypeOptions},on:{"update:itemType":function($event){return _vm.$set(item, "id", $event)},"update:item-type":function($event){return _vm.$set(item, "id", $event)}}})],1),_c('b-col',{attrs:{"md":"3"}},[_c('text-input',_vm._b({attrs:{"text":item.note},on:{"update:text":function($event){return _vm.$set(item, "note", $event)}}},'text-input',{
              id:'name',
              name:'Name',
            },false))],1),_c('b-col',{attrs:{"md":"3"}},[_c('text-input',_vm._b({attrs:{"text":item.price},on:{"update:text":function($event){return _vm.$set(item, "price", $event)}}},'text-input',{
              id:'price',
              name:'Price',
              placeholder:'CAD',
              rules:'min_value:1'
            },false))],1),_c('b-col',{staticClass:"mb-50",attrs:{"lg":"2","md":"3"}},[_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(234, 84, 85, 0.15)'),expression:"'rgba(234, 84, 85, 0.15)'",modifiers:{"400":true}}],staticClass:"mt-0 mt-md-2",attrs:{"variant":"outline-danger"},on:{"click":function($event){return _vm.removeItem(index)}}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"XIcon"}}),_c('span',[_vm._v("Delete")])],1)],1),_c('b-col',{attrs:{"cols":"12"}},[_c('hr')])],1)}),1)],1),_c('b-button',{directives:[{name:"ripple",rawName:"v-ripple.400",value:('rgba(255, 255, 255, 0.15)'),expression:"'rgba(255, 255, 255, 0.15)'",modifiers:{"400":true}}],attrs:{"variant":"primary"},on:{"click":_vm.repeatAgain}},[_c('feather-icon',{staticClass:"mr-25",attrs:{"icon":"PlusIcon"}}),_c('span',[_vm._v("Add New")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }
<template>
  <fieldset :disabled="disabled">
    <!-- Main Info -->
    <b-tab :disabled="mainTabDisabled">
      <template #title>
        <span>Fundraising Main Info</span>
      </template>

      <b-card>
        <main-info
          :submit="submitFunctions.submitCrowdFund"
          :info.sync="crowdfund.mainInfo"
          :disabled="disabled"
        />
      </b-card>
    </b-tab>

    <!-- Case Location -->
    <b-tab
      :disabled="!crowdfund.id"
      lazy
    >
      <template #title>
        <span>Fundraising Case Location</span>
      </template>

      <b-card>
        <validation-observer #default="{handleSubmit}">
          <b-form @submit.prevent>
            <location-inputs
              :details.sync="crowdfund.location"
            />
            <div
              v-if="!disabled"
              class="text-right mt-3"
            >
              <b-button
                variant="primary"
                class="mx-1"
                @click="handleSubmit(submitFunctions.addCrowdFundLocation)"
              >
                Save
              </b-button>
            </div>
          </b-form>
        </validation-observer>
      </b-card>
    </b-tab>

    <!-- Project Plan -->
    <b-tab
      :disabled="!crowdfund.id"
      lazy
    >
      <template #title>
        <span>Fundraising Project Plan</span>
      </template>

      <b-card>
        <project-plan
          :submit="submitFunctions.addCrowdFundPlan"
          :plan.sync="crowdfund.plan"
          :disabled="disabled"
        />
      </b-card>
    </b-tab>

    <!-- Project Media -->
    <b-tab
      :disabled="!crowdfund.id"
      lazy
    >
      <template #title>
        <span>Fundraising Project Media</span>
      </template>

      <b-card>
        <project-media
          :submit="submitFunctions.addCrowdFundMedia"
          :media.sync="crowdfund.media"
          :disabled="disabled"
        />
      </b-card>
    </b-tab>
  </fieldset>
</template>
<script>
import LocationInputs from '@/common/components/common/FormInputs/LocationInputs.vue'
import ProjectPlan from './Tabs/ProjectPlan.vue'
import MainInfo from './Tabs/MainInfo.vue'
import ProjectMedia from './Tabs/ProjectMedia.vue'

export default {
  name: 'CrowdFundForm',
  components: {
    LocationInputs,
    ProjectPlan,
    MainInfo,
    ProjectMedia,
  },
  props: {
    disabled: { type: Boolean, default: false },
    mainTabDisabled: { type: Boolean, default: false },
    crowdfund: { type: Object, default: () => {} },
    submitFunctions: { type: Object, default: () => {} },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <b-form-group
    label="Frequency"
    label-for="frequency"
  >
    <b-form-select
      id="frequency"
      v-model="tempFrequency"
      :options="frequencyOptions"
    />
  </b-form-group>
</template>
<script>
export default {
  name: 'FrequencyTypeSelect',
  props: {
    frequency: { type: String, default: '' },
  },
  data() {
    return {
      frequencyOptions: [
        { text: 'Once', value: 'once' },
        { text: 'Daily', value: 'daily' },
        { text: 'Weekly', value: 'weekly' },
        { text: 'Multiple Dates', value: 'custom' },
      ],
    }
  },
  computed: {
    tempFrequency: {
      get() {
        return this.frequency
      },
      set(value) {
        this.$emit('update:frequency', value)
      },
    },
  },
}
</script>
<style lang="">

</style>

<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent="handleSubmit(saveSettings)">
      <b-row class="p-1">
        <b-col>
          <b-row>
            <!-- Is Fundraising Event -->
            <b-col md="4">
              <b-form-group
                label="Fundraising Event"
                label-class="font-weight-bold"
                label-for="is-fundraising-event"
              >
                <b-form-radio-group
                  id="is-fundraising-event"
                  v-model="isFundraising"
                  :options="statusOptions"
                />
              </b-form-group>
            </b-col>
            <!-- Is Fundraising Exist -->
            <b-col
              v-show="isFundraising"
              md="3"
            >
              <b-form-group
                label="Fundraising Project Exist"
                label-class="font-weight-bold"
                label-for="is-fundraising-exist"
              >
                <b-form-radio-group
                  id="is-fundraising-exist"
                  v-model="isFundraisingExist"
                  :options="statusOptions"
                />
              </b-form-group>
            </b-col>
            <!-- Select Fundraising -->
            <b-col
              v-if="isFundraising && isFundraisingExist"
              md="4"
            >
              <fundraising-project-select
                rules="required"
                :filter="{
                  'type':'crowdfunding',
                  upcoming:true,
                  active:true,
                }"
                :fundraising-id.sync="fundraisingId"
              />
            </b-col>
          </b-row>
        </b-col>
        <b-col
          md="2"
          class="mt-5"
        >
          <b-button
            variant="primary"
            class="btn-block"
            type="submit"
          >
            Next
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </validation-observer>
</template>
<script>
import FundraisingProjectSelect from '@/common/components/Fundraising/FundraisingProjectSelect.vue'

export default {
  name: 'EventWizard',
  components: {
    FundraisingProjectSelect,
  },
  data() {
    return {
      isFundraising: false,
      isFundraisingExist: false,
      fundraisingId: null,
      statusOptions: [
        { text: 'Yes', value: true },
        { text: 'No', value: false },
      ],
    }
  },
  methods: {
    saveSettings() {
      this.$emit('update:isFundraising', this.isFundraising)
      this.$emit('update:isFundraisingExist', this.isFundraisingExist)
      this.$emit('update:fundraisingId', this.fundraisingId)
      this.$emit('update:isWizardSaved', true)
    },
  },
}
</script>
<style lang="">

</style>

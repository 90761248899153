<template>
  <div>
    <b-card
      v-if="!isWizardSaved && $can('save','FundraisingController')"
      header="Event Type"
    >
      <event-wizard
        :is-fundraising.sync="isFundraising"
        :is-fundraising-exist.sync="isFundraisingExist"
        :fundraising-id.sync="fundraisingId"
        :is-wizard-saved.sync="isWizardSaved"
      />
    </b-card>

    <b-card v-else-if="isWizardSaved || !$can('save','FundraisingController')">
      <div
        v-if="event.id"
        class="text-right mb-1"
      >
        <b-button
          variant="primary"
          @click="redirectToSessionsList"
        >
          Manage Sessions
        </b-button>
      </div>

      <b-tabs>
        <!-- Event -->
        <b-tab>
          <template #title>
            <span>Event</span>
          </template>

          <template v-if="isSessionsTypeWizardSaved">
            <single-session-event-form
              v-if="event.singleSession"
              :event="event"
              :session="session"
              :submit="submitSingleSessionEvent"
            />

            <event-form
              v-else
              :submit="submitEvent"
              :event.sync="event"
            />
          </template>

          <event-sessions-type-wizard
            v-else
            :sessions-type.sync="event.singleSession"
            :is-sessions-type-wizard-saved.sync="isSessionsTypeWizardSaved"
          />

          <upload-progress :progress="progress" />
        </b-tab>

        <!-- Fundraising Event -->
        <crowd-fundraising-form
          v-if="isFundraising"
          :submit-functions="{
            submitCrowdFund:submitFundraising,
            addCrowdFundLocation,
            addCrowdFundPlan,
            addCrowdFundMedia,
          }"
          :main-tab-disabled="fundraisingNotExist && eventNotCreated"
          :crowdfund.sync="crowdfund"
        />
      </b-tabs>

    </b-card>
  </div>
</template>
<script>
import handleAlerts from '@/common/compositions/common/handleAlerts'
import CrowdFundraisingForm from '@/common/components/Fundraising/CrowdFundraising/CrowdFundraisingForm.vue'
import EventForm from '@/common/components/QuickEvents/EventForm.vue'
import FundraisingApi from '@/common/compositions/Fundraising/FundraisingApi'
import UploadProgress from '@/common/components/common/FormInputs/UploadProgress.vue'
import EventWizard from '@/common/components/QuickEvents/EventWizard.vue'
import EventSessionsTypeWizard from '@/common/components/QuickEvents/EventSessionsTypeWizard.vue'
import SingleSessionEventForm from '@/common/components/QuickEvents/SingleSessionEventForm.vue'
import QuickEventApi from '@/common/compositions/QuickEvents/quickEventApi'
import SessionApi from '@/common/compositions/QuickEvents/sessionApi'

export default {
  name: 'AddCrowdFundEvent',
  components: {
    CrowdFundraisingForm,
    EventForm,
    UploadProgress,
    EventWizard,
    EventSessionsTypeWizard,
    SingleSessionEventForm,
  },
  data() {
    return {
      isFundraising: false,
      isFundraisingExist: false,
      isWizardSaved: false,
      isSessionsTypeWizardSaved: false,
      fundraisingId: null,
    }
  },
  computed: {
    submitEvent() {
      return this.isFundraisingExist ? this.addEventWithExistFundraising : this.addEvent
    },
    submitFundraising() {
      return this.isFundraisingExist ? this.editCrowdFundMainInfo : this.addCrowdFundMainInfo
    },
    fundraisingNotExist() {
      return !this.isFundraisingExist
    },
    eventNotCreated() {
      return !this.event.id
    },
  },
  provide() {
    return {
      singleSession: this.event.singleSession,
    }
  },
  watch: {
    isWizardSaved() {
      if (this.isFundraisingExist) {
        this.getFundraisingProject(this.fundraisingId)
      }
    },
  },
  setup() {
    const { successfulOperationAlert } = handleAlerts()
    const {
      addCrowdFundMainInfo, editCrowdFundMainInfo, addCrowdFundLocation, addCrowdFundMedia, addCrowdFundPlan, crowdfund, getFundraisingProject,
    } = FundraisingApi()

    const { event, progress, addEventRequest } = QuickEventApi()
    const {
      session, addSessionRequest,
    } = SessionApi()

    crowdfund.value.mainInfo.type = 'event'

    return {
      successfulOperationAlert,
      crowdfund,
      addCrowdFundMainInfo,
      editCrowdFundMainInfo,
      addCrowdFundLocation,
      addCrowdFundMedia,
      addCrowdFundPlan,
      getFundraisingProject,
      session,
      addSessionRequest,
      event,
      progress,
      addEventRequest,
    }
  },
  methods: {
    submitSingleSessionEvent() {
      return this.addEvent().then(res => {
        const event = res.data.data[0] || {}
        this.setDuplicateDataInSession(event)
        return this.addSessionRequest(event.id)
      })
    },
    setDuplicateDataInSession(event) {
      this.$set(this.session, 'topic', event.name)
      this.$set(this.session, 'start_time', event.startTime)
      this.$set(this.session, 'end_time', event.endTime)
      this.$set(this.session, 'general_notes', event.generalNotes)
    },
    addEventWithExistFundraising() {
      return this.addEvent().then(() => {
        this.attachFundraisingToEvent()
      })
    },
    attachFundraisingToEvent() {
      this.$activities.put(`/internalops/fundraising/${this.fundraisingId}/set-event-type`, {
        type: 'event',
        typeId: this.event.id,
      }).then(() => {
        this.successfulOperationAlert('Fundraising is attached successfully')
      })
    },
    addEvent() {
      return this.addEventRequest().then(res => {
        const event = res.data.data[0]

        this.$set(this.event, 'id', event.id)

        this.setCrowdFund(event)

        this.successfulOperationAlert('Event is added successfully')
        this.confirmGoingToSessions()
        return res
      })
    },
    setCrowdFund(event) {
      this.$set(this.crowdfund.media, 'imageCover', [event.image])
      this.$set(this.crowdfund.mainInfo, 'typeId', event.id)
    },
    confirmGoingToSessions() {
      if (this.event.singleSession && !this.isFundraising) {
        this.$router.push({ name: 'quick-events-list' })
      } else if (!this.isFundraising) {
        this.$swal({
          title: 'Event has been created successfully',
          text: 'Are you going to add sessions now ?',
          icon: 'question',
          showCancelButton: true,
          confirmButtonText: 'Go to Sessions',
          cancelButtonText: 'Go to list',
        }).then(result => {
          if (result.isConfirmed) {
            this.redirectToSessionsList()
          } else {
            this.$router.push({ name: 'quick-events-list' })
          }
        })
      }
    },
    redirectToSessionsList() {
      this.$router.push({ name: 'quick-event-sessions-list', params: { eventId: this.event.id } })
    },
  },
}
</script>
<style lang="scss">

</style>

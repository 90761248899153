<template>
  <div class="my-2 border p-2">
    <h5 class="mb-2">
      Project check list
    </h5>
    <div>
      <b-form
        ref="form"
        :style="{height: trHeight}"
        class="repeater-form"
        @submit.prevent="repeatAgain"
      >

        <!-- Row Loop -->
        <b-form-row
          v-for="(item, index) in itemsProxy"
          :key="item.id"
          ref="row"
        >
          <!-- Task Name -->
          <b-col md="3">
            <text-input
              v-bind="{
                id:'name',
                name:'Task Name',
              }"
              :text.sync="item.name"
            />
          </b-col>

          <!-- Remove Button -->
          <b-col
            lg="2"
            md="3"
            class="mb-50"
          >
            <b-button
              v-ripple.400="'rgba(234, 84, 85, 0.15)'"
              variant="outline-danger"
              class="mt-0 mt-md-2"
              @click="removeItem(index)"
            >
              <feather-icon
                icon="XIcon"
                class="mr-25"
              />
              <span>Delete</span>
            </b-button>
          </b-col>
          <b-col cols="12">
            <hr>
          </b-col>
        </b-form-row>

      </b-form>
    </div>
    <b-button
      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
      variant="primary"
      @click="repeatAgain"
    >
      <feather-icon
        icon="PlusIcon"
        class="mr-25"
      />
      <span>Add Task</span>
    </b-button>
  </div>
</template>

<script>
import { heightTransition } from '@core/mixins/ui/transition'
import Ripple from 'vue-ripple-directive'
import TextInput from '../../../common/FormInputs/TextInput.vue'

export default {
  name: 'CheckList',
  components: { TextInput },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  props: {
    items: { type: Array, default: () => [] },
  },
  data() {
    return {
      nextTodoId: 2,
    }
  },
  computed: {
    itemsProxy: {
      get() {
        return this.items
      },
      set(value) {
        this.$emit('update:items', value)
      },
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight)
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
  methods: {
    repeatAgain() {
      this.itemsProxy.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.itemsProxy.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form.scrollHeight)
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>

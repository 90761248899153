<template>
  <div class="my-2 border p-2">
    <b-form-group
      label-for="ticket-price"
    >
      <template #label>
        <h5>Ticket Details</h5>
      </template>
      <b-form-checkbox
        v-model="paidTicket.paid"
        value="free"
        inline
      >
        <b>Free</b>
      </b-form-checkbox>

      <b-form-checkbox
        v-model="paidTicket.paid"
        value="mark-attending"
        inline
      >
        <b>Mark Attending</b>
      </b-form-checkbox>

      <b-form-checkbox
        v-model="paidTicket.paid"
        value="paid"
        inline
        :disabled="!isOrgLinkedWithStripe"
      >
        <b>Paid</b>
      </b-form-checkbox>
    </b-form-group>

    <!-- Paid Ticket -->
    <b-form-row v-if="paidTicket.paid === 'paid'">
      <!-- Tax Ratio -->
      <b-col md="3">
        <text-input
          id="tax-ratio"
          name="Tax Ratio"
          rules="required|max_value:100"
          type="number"
          :text.sync="tempPaidTicket.tax_ratio"
        />
      </b-col>

      <!-- Ticket Price -->
      <b-col md="3">
        <text-input
          id="ticket-price"
          name="Ticket Price"
          rules="required|min_value:1"
          type="number"
          :text.sync="tempPaidTicket.price"
        />
      </b-col>

      <!-- Payment Method -->
      <b-col md="3">
        <payment-method-select
          :payment-method.sync="tempPaidTicket.payment_method"
          rules="required"
        />
      </b-col>

      <!-- Fees Payer -->
      <b-col md="3">
        <fees-payer-select
          rules="required"
          :fees-payer.sync="tempPaidTicket.user_covers_comissions"
        />
      </b-col>

      <!-- Ticket Type -->
      <b-col md="3">
        <b-form-group
          label="Ticket Type"
          label-for="ticket-type"
        >
          <b-form-select
            id="ticket-type"
            v-model="ticketTypeProxy"
            :options="ticketTypesOptions"
          />
        </b-form-group>
      </b-col>

      <!-- Charity Ticket -->
      <b-col
        v-if="ticketType === 'charity'"
        md="6"
      >
        <charity-ticket
          :ticket-price="tempPaidTicket.price"
          :deduction-amount.sync="tempPaidTicket.charity_deduction_amount"
          :deduction-type.sync="tempPaidTicket.charity_deduction_type"
        />
      </b-col>

      <!-- Donation Ticket -->
      <b-col
        v-else-if="ticketType === 'donation'"
        md="9"
      >
        <b-form-row>
          <b-col md="8">
            <charity-ticket
              :ticket-price="tempPaidTicket.price"
              :deduction-amount.sync="tempPaidTicket.donation_deduction_amount"
              :deduction-type.sync="tempPaidTicket.donation_deduction_type"
            />
          </b-col>

          <b-col md="4">
            <inpo-linked-with-stripe-select
              :inpo-id.sync="tempPaidTicket.charity_id"
            />
          </b-col>
        </b-form-row>
      </b-col>

      <!-- Services -->
      <b-col cols="12">
        <session-services :services.sync="tempPaidTicket.additional_services" />
      </b-col>
    </b-form-row>
  </div>
</template>
<script>
import TextInput from '@/common/components/common/FormInputs/TextInput.vue'
import InpoLinkedWithStripeSelect from '@/common/components/LiveManagement/InpoLinkedWithStripeSelect.vue'
import CharityTicket from './Paid/Types/CharityTicket.vue'
import SessionServices from '../SessionServices.vue'
import PaymentMethodSelect from './PaymentMethodSelect.vue'
import FeesPayerSelect from './FeesPayerSelect.vue'

export default {
  name: 'TicketPrice',
  components: {
    CharityTicket,
    TextInput,
    InpoLinkedWithStripeSelect,
    SessionServices,
    PaymentMethodSelect,
    FeesPayerSelect,
  },
  props: {
    paidTicket: { type: Object, default: () => {} },
  },
  data() {
    return {
      // eslint-disable-next-line no-nested-ternary
      ticketType: this.paidTicket.donation_deduction_amount ? 'donation' : (this.paidTicket.charity_deduction_amount ? 'charity' : 'full_ticket'),
      ticketTypesOptions: [
        { text: 'Full Ticket', value: 'full_ticket' },
        { text: 'Charity', value: 'charity' },
        { text: 'Donation', value: 'donation' },
      ],
      isOrgLinkedWithStripe: false,
    }
  },
  computed: {
    tempPaidTicket: {
      get() {
        return this.paidTicket
      },
      set(value) {
        this.$emit('update:paidTicket', value)
      },
    },
    ticketTypeProxy: {
      get() {
        return this.ticketType
      },
      set(value) {
        this.ticketType = value
        switch (value) {
          case 'charity':
            this.resetTicketTypeDetails('donation')
            break
          case 'donation':
            this.resetTicketTypeDetails('charity')
            break
          case 'full_ticket':
            this.resetTicketTypeDetails('donation')
            this.resetTicketTypeDetails('charity')
            break
          default:
        }
      },
    },
  },
  created() {
    this.checkStripeLinked()
  },
  methods: {
    checkStripeLinked() {
      this.$entities.post('/internalops/stripe/check-link', {
        entity_id: this.$store.getters['mainEntity/getEntityId'],
      }).then(res => {
        this.isOrgLinkedWithStripe = res.data.success
      }).catch(() => {
        this.isOrgLinkedWithStripe = false
      })
    },
    resetTicketTypeDetails(ticketType) {
      this.$set(this.tempPaidTicket, `${ticketType}_deduction_amount`, null)
      this.$set(this.tempPaidTicket, `${ticketType}_deduction_type`, null)
      if (['full_ticket', 'donation'].includes(ticketType)) {
        this.$set(this.tempPaidTicket, 'charity_id', null)
      }
    },
  },
}
</script>
<style lang="scss">

</style>

<template>
  <validation-observer #default="{handleSubmit}">
    <b-form @submit.prevent>
      <rich-text-editor
        name="Message"
        rules="required|min:3|max:500"
        :description.sync="note.message"
      />

      <b-button
        variant="primary"
        @click="handleSubmit(submit)"
      >
        Save
      </b-button>
    </b-form>
  </validation-observer>
</template>
<script>
import RichTextEditor from '@/common/components/common/FormInputs/RichTextEditor.vue'

export default {
  name: 'LogNotesForm',
  components: { RichTextEditor },
  props: {
    note: { type: Object, default: () => {} },
    submit: { type: Function, default: () => {} },
  },
}
</script>
<style lang="scss">
.ql-editor{
  min-height: 100px;
}
</style>

<template>
  <custom-vue-select
    id="gender-select"
    label="Gender"
    placeholder="Please select gender"
    :rules="rules"
    :value-reducer="option=>option.value"
    :options="genderOptions"
    :disabled="$attrs.disabled"
    text-field="text"
    :selected.sync="genderProxy"
  />
</template>
<script>
import CustomVueSelect from './CustomVueSelect.vue'

export default {
  name: 'GenderSelect',
  components: { CustomVueSelect },
  props: {
    gender: { type: String, default: '' },
    rules: { type: String, default: '' },
  },
  computed: {
    genderProxy: {
      get() {
        return this.gender
      },
      set(value) {
        this.$emit('update:gender', value)
      },
    },
    genderOptions() {
      return [
        { text: 'Male', value: 'male' },
        { text: 'Female', value: 'female' },
        { text: 'Both', value: 'both' },
        { text: 'Don\'t display on app', value: null },
      ]
    },
  },
}
</script>
<style lang="scss">

</style>

import axiosInstances from '@/libs/axios-instances'
import { computed, ref } from '@vue/composition-api'

export default function Session() {
  const session = ref({
    age_segments: [],
    restriction_notes: [],
    paidTicket: { paid: 'free', additional_services: [] },
    auto_approval: true,
    vaccination_required: false,
    status: false,
    attendees_name_required: false,
    target_gender: 'male',
    max_male_number: 0,
    max_female_number: 0,
    general_notes: '',
  })

  const isSessionPaid = computed(() => session.value.paidTicket.paid === 'paid')

  const formattedSession = computed(() => ({
    topic: session.value.topic,
    speaker: session.value.speaker,
    url: session.value.url,
    venue: session.value.venue,
    language: session.value.language,
    start_time: session.value.start_time,
    end_time: session.value.end_time,
    capacity: session.value.capacity,
    target_gender: session.value.target_gender,
    max_male_number: session.value.target_gender === 'male' ? session.value.capacity : session.value.max_male_number,
    max_female_number: session.value.target_gender === 'female' ? session.value.capacity : session.value.max_female_number,
    auto_approval: session.value.auto_approval,
    vaccination_required: session.value.vaccination_required,
    status: session.value.status,
    attendees_name_required: session.value.attendees_name_required,
    general_notes: session.value.general_notes,
    age_segments: session.value.age_segments,
    restriction_notes: session.value.restriction_notes?.map(note => note.note),

    additional_services: isSessionPaid.value
      ? session.value.paidTicket.additional_services
      : [],

    ...(isSessionPaid.value ? session.value.paidTicket : {
      paid: session.value.paidTicket.paid,
    }),
  }))

  const getSessionRequest = (eventId, sessionId) => axiosInstances.activities.get(`/internalops/events/${eventId}/sessions/${sessionId}`).then(res => res)

  const addSessionRequest = eventId => axiosInstances.activities.post(`/internalops/events/${eventId}/sessions`, formattedSession.value).then(res => res)

  const editSessionRequest = (eventId, sessionId) => axiosInstances.activities.post(`/internalops/events/${eventId}/sessions/${sessionId}?_method=PUT`, formattedSession.value).then(res => res)

  return {
    session, getSessionRequest, addSessionRequest, editSessionRequest, isSessionPaid, formattedSession,
  }
}
